import {Helmet} from 'react-helmet'
import Shell from '@/components/layout/templates/Clean'
import Login from '@/components/auth/Login'

export default function LoginPage() {
  return (
    <Shell noLogo>
      <Helmet>
        <title>EmCasa - Login</title>
      </Helmet>
      <Login />
    </Shell>
  )
}
